import React from 'react';
import Burger from '../Burger/Burger';
import './Header.css';
function Header() {
  return (
    <div>
      <Burger />
    </div>
  )
}

export default Header;
